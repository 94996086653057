'use client';

import Link from 'next/link';

import { LogoSmall } from 'components/LogoSmall';
import { useBreakpoints } from 'hooks/breakpoints';

import { LightLogo } from '../LightLogo';
import { Logo } from '../Logo';

export const HeaderLogo: React.FC<
  React.SVGProps<SVGSVGElement> & { theme?: string }
> = (props) => {
  const { isMd } = useBreakpoints();
  return (
    <Link aria-label="Home" href="/">
      {props?.theme === 'light' ? (
        <LightLogo />
      ) : isMd ? (
        <Logo {...props} />
      ) : (
        <LogoSmall />
      )}
    </Link>
  );
};
