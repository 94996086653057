export const LightLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    className="inline-block h-6 lg:h-7"
    fill="none"
    viewBox="0 0 143 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M54.8871 12.0244C54.1501 7.29064 50.5785 4.45605 46.1282 4.45605C40.6319 4.45605 36.4368 8.65124 36.4368 14.0086C36.4368 19.366 40.5753 23.5271 46.2699 23.5271C49.1506 23.53 51.9333 22.4818 54.0962 20.5792C54.1857 20.4994 54.2477 20.3934 54.2731 20.2762C54.2986 20.1591 54.2863 20.0369 54.2379 19.9272L53.3564 17.8013C53.2998 17.6624 53.1904 17.5516 53.0523 17.4931C52.9142 17.4347 52.7585 17.4334 52.6194 17.4895C52.5566 17.5171 52.499 17.5555 52.4493 17.6028C50.4935 19.3886 48.4242 20.1823 46.2983 20.1823C44.0306 20.1823 42.3015 19.2186 41.2244 17.6879C41.1428 17.5623 41.1121 17.4104 41.1384 17.263C41.1647 17.1156 41.2461 16.9838 41.3661 16.8942C41.4228 16.8659 41.4511 16.8375 41.5078 16.8092L54.4335 12.614C54.5034 12.6084 54.5713 12.5882 54.6329 12.5547C54.6944 12.5213 54.7483 12.4753 54.7911 12.4198C54.8338 12.3642 54.8644 12.3003 54.8809 12.2322C54.8975 12.1642 54.8996 12.0933 54.8871 12.0244ZM40.1841 13.8669C40.2975 10.4087 42.8231 7.77252 46.1367 7.77252C48.1776 7.77252 49.8784 8.79296 50.6437 10.4654L40.1841 13.8669Z"
        fill="#093545"
      />
      <path
        d="M72.7195 4.48423L69.2868 4.90942C69.1842 4.9207 69.0862 4.95782 69.002 5.01731C68.9177 5.07681 68.8499 5.15672 68.8049 5.24957L64.468 15.8226L60.1878 5.27792C60.15 5.18283 60.0885 5.09902 60.0091 5.03451C59.9297 4.96999 59.8351 4.92692 59.7343 4.90942L56.2761 4.48423C56.1296 4.45937 55.9793 4.49295 55.8574 4.57776C55.7355 4.66257 55.6517 4.79184 55.6241 4.93777C55.6009 5.05427 55.6211 5.17522 55.6808 5.27792L63.4277 23.1925C63.4731 23.2966 63.5487 23.3847 63.6447 23.4453C63.7407 23.506 63.8528 23.5364 63.9663 23.5326H64.9867C65.0995 23.5328 65.21 23.5009 65.3053 23.4406C65.4007 23.3804 65.477 23.2944 65.5253 23.1925L73.3204 5.27792C73.3535 5.20928 73.3723 5.13469 73.3759 5.0586C73.3795 4.9825 73.3677 4.90647 73.3413 4.83503C73.3148 4.76359 73.2742 4.69822 73.2219 4.64282C73.1696 4.58742 73.1067 4.54312 73.0369 4.51258C72.936 4.47296 72.8258 4.46313 72.7195 4.48423Z"
        fill="#093545"
      />
      <path
        d="M124.023 4.48436L120.564 4.90955C120.464 4.92706 120.369 4.97012 120.29 5.03464C120.21 5.09916 120.149 5.18296 120.111 5.27805L115.831 15.8227L111.465 5.2497C111.426 5.15819 111.364 5.07857 111.284 5.01887C111.205 4.95916 111.111 4.92146 111.012 4.90955L107.582 4.48436C107.507 4.47232 107.431 4.4753 107.358 4.49312C107.284 4.51093 107.215 4.54324 107.155 4.58813C107.094 4.63302 107.043 4.68959 107.004 4.75452C106.966 4.81945 106.94 4.89144 106.93 4.96624C106.907 5.08275 106.927 5.2037 106.987 5.30639L114.782 23.221C114.821 23.3295 114.895 23.4218 114.993 23.4834C115.09 23.545 115.206 23.5724 115.32 23.5611H116.341C116.453 23.5606 116.564 23.5284 116.659 23.4683C116.754 23.4081 116.831 23.3224 116.879 23.221L124.655 5.27805C124.689 5.21131 124.709 5.13847 124.715 5.06376C124.721 4.98904 124.712 4.91392 124.688 4.84275C124.665 4.77158 124.627 4.70577 124.578 4.64912C124.529 4.59247 124.469 4.5461 124.402 4.51271C124.283 4.45974 124.149 4.44973 124.023 4.48436Z"
        fill="#093545"
      />
      <path
        d="M83.6582 4.48438C78.1903 4.48438 73.8506 8.67956 73.8506 14.0369C73.8506 19.3943 78.1875 23.5271 83.6582 23.5271C89.129 23.5271 93.4744 19.3659 93.4744 14.0086C93.4744 8.65121 89.1573 4.48438 83.6582 4.48438ZM83.6582 20.0179C82.4642 20.0229 81.2956 19.6738 80.3 19.0147C79.3043 18.3556 78.5265 17.4161 78.0648 16.315C77.6031 15.2138 77.4782 14.0005 77.706 12.8284C77.9338 11.6564 78.504 10.5781 79.3445 9.73009C80.1851 8.88204 81.2582 8.30225 82.4282 8.06402C83.5982 7.82578 84.8125 7.93981 85.9177 8.39168C87.0229 8.84355 87.9693 9.61297 88.6373 10.6027C89.3053 11.5924 89.6648 12.7579 89.6704 13.9519C89.6816 14.7383 89.5369 15.5192 89.2446 16.2494C88.9522 16.9796 88.5181 17.6446 87.9672 18.206C87.4164 18.7674 86.7597 19.2141 86.0352 19.5202C85.3106 19.8264 84.5326 19.9859 83.7461 19.9895C83.7344 20.0003 83.7204 20.0084 83.7053 20.0133C83.6901 20.0182 83.6741 20.0198 83.6582 20.0179Z"
        fill="#093545"
      />
      <path
        d="M108.832 19.9328H100.81V5.05127C100.81 4.97437 100.795 4.89826 100.765 4.82757C100.734 4.75687 100.69 4.69306 100.634 4.64003C100.579 4.587 100.513 4.54585 100.441 4.51909C100.369 4.49233 100.292 4.48051 100.215 4.48435H100.13L97.352 4.90954C97.2168 4.93004 97.0936 4.99871 97.0051 5.10288C96.9165 5.20706 96.8686 5.33974 96.8702 5.47646V22.9375C96.8701 23.0144 96.8856 23.0905 96.9158 23.1612C96.9461 23.2319 96.9904 23.2957 97.0461 23.3487C97.1018 23.4017 97.1677 23.4429 97.2397 23.4697C97.3118 23.4964 97.3886 23.5082 97.4654 23.5044H108.435C108.572 23.5059 108.705 23.458 108.809 23.3695C108.913 23.2809 108.982 23.1577 109.002 23.0225L109.399 20.6131C109.424 20.4667 109.39 20.3164 109.305 20.1945C109.221 20.0725 109.091 19.9888 108.945 19.9612C108.886 19.9612 108.858 19.9328 108.832 19.9328Z"
        fill="#093545"
      />
      <path
        d="M143.439 12.0244C142.702 7.26229 139.131 4.45605 134.681 4.45605C129.181 4.45605 125.015 8.65124 125.015 14.0086C125.015 19.366 129.153 23.5271 134.859 23.5271C137.74 23.5305 140.523 22.4822 142.685 20.5792C142.775 20.4994 142.837 20.3934 142.862 20.2762C142.888 20.1591 142.875 20.0369 142.827 19.9272L141.946 17.8069C141.889 17.668 141.78 17.5572 141.642 17.4988C141.503 17.4404 141.348 17.439 141.209 17.4951C141.146 17.5228 141.088 17.5612 141.039 17.6085C139.083 19.3943 137.013 20.188 134.887 20.188C132.62 20.188 130.891 19.2242 129.814 17.6936C129.731 17.5682 129.7 17.4161 129.727 17.2685C129.753 17.1209 129.835 16.989 129.955 16.8999C130.006 16.878 130.054 16.8494 130.097 16.8148L143.079 12.6763C143.208 12.6294 143.315 12.5376 143.381 12.4178C143.448 12.2979 143.468 12.1582 143.439 12.0244ZM128.756 13.8669C128.87 10.4087 131.392 7.77252 134.709 7.77252C136.753 7.77252 138.453 8.79296 139.216 10.4654L128.756 13.8669Z"
        fill="#093545"
      />
      <path
        d="M20.0784 11.1456L15.5998 6.66696C15.3029 6.38082 14.9066 6.22095 14.4943 6.22095C14.082 6.22095 13.6857 6.38082 13.3888 6.66696L8.91301 11.1456C8.62154 11.4396 8.45846 11.8371 8.45948 12.2511V16.2195C9.46517 15.7911 10.5092 15.4589 11.5775 15.2274V12.903L14.4971 9.98342L17.4168 12.903V14.377C18.4939 14.2069 19.0041 14.1219 19.5143 14.0085C19.7695 13.9518 20.138 13.8668 20.5631 13.7534V12.2794C20.5459 11.8549 20.3734 11.4514 20.0784 11.1456Z"
        fill="#093545"
      />
      <path
        d="M27.0231 7.77246C25.601 4.87619 23.2207 2.56111 20.286 1.22005C17.3513 -0.121009 14.043 -0.405454 10.9225 0.414981C7.802 1.23542 5.06143 3.1102 3.16582 5.72122C1.27021 8.33224 0.336311 11.5187 0.522593 14.7399C0.534876 14.9616 0.590904 15.1787 0.68745 15.3787C0.783997 15.5787 0.919155 15.7576 1.08514 15.9052C1.25113 16.0527 1.44466 16.166 1.6546 16.2384C1.86453 16.3108 2.08673 16.341 2.30838 16.3272C2.56965 16.3117 2.8238 16.236 3.05104 16.1061C3.32714 15.9477 3.55322 15.7149 3.70356 15.4342C3.85389 15.1536 3.92244 14.8364 3.90141 14.5188V14.0085C3.90045 11.5935 4.72454 9.25053 6.23719 7.36785C7.74984 5.48517 9.86026 4.17573 12.2188 3.65645C14.5774 3.13717 17.0426 3.43921 19.2061 4.51254C21.3696 5.58587 23.1014 7.36608 24.1148 9.55825C24.4132 10.2125 24.6413 10.8967 24.7951 11.5991C25.8382 11.0394 26.8506 10.4244 27.8281 9.75667C27.6265 9.07013 27.3568 8.40543 27.0231 7.77246Z"
        fill="#093545"
      />
      <path
        d="M27.1931 19.9044C25.6238 23.2673 22.7859 25.8713 19.3008 27.1463C15.8156 28.4214 11.9673 28.2635 8.59829 26.7074C7.18072 26.0447 5.88133 25.1541 4.75176 24.0712C4.24184 23.5667 3.76823 23.0268 3.33447 22.4555C3.61793 22.2571 3.75966 22.172 3.78801 22.1437C4.66673 21.5484 5.48875 21.0382 6.25409 20.613C6.72248 21.1902 7.24449 21.7217 7.81311 22.2004C9.98607 23.9723 12.7737 24.8088 15.5632 24.526C18.3528 24.2432 20.9158 22.8643 22.689 20.6924C23.2467 20.0193 23.7136 19.276 24.0779 18.4814C24.2367 18.1189 24.5321 17.8337 24.9 17.6877L24.9283 17.6594C25.133 17.57 25.3534 17.5218 25.5767 17.5176C25.8001 17.5134 26.022 17.5533 26.23 17.6349C26.4379 17.7165 26.6278 17.8382 26.7887 17.9932C26.9495 18.1482 27.0783 18.3333 27.1676 18.5381C27.2642 18.7522 27.3162 18.9838 27.3206 19.2186C27.325 19.4535 27.2816 19.6868 27.1931 19.9044Z"
        fill="#093545"
      />
      <path
        d="M25.6059 15.0856L25.5208 15.1423C25.3791 15.2273 24.9256 15.4258 24.2736 15.7092C23.7924 15.9236 23.3005 16.1128 22.7997 16.2761C22.0627 16.5596 21.4674 16.7297 21.1839 16.8147C20.4753 17.0415 19.88 17.1549 19.5115 17.2399C19.0013 17.3533 18.5194 17.4383 17.4139 17.6084C16.3085 17.7785 15.288 17.9202 14.8912 17.9769C13.7857 18.1186 12.7085 18.2887 11.5747 18.5438C10.5092 18.7982 9.46639 19.1395 8.45667 19.5642C7.6982 19.8774 6.95981 20.2372 6.24569 20.6414C5.48036 21.0666 4.65833 21.5768 3.77961 22.1721L3.32608 22.4555V22.4272C3.32608 22.4073 3.27505 22.3535 3.26088 22.3336C3.24671 22.3138 3.21836 22.2684 3.19852 22.2373C3.17868 22.2061 3.11632 22.104 3.07947 22.036C3.00915 21.8997 2.94853 21.7585 2.89805 21.6136C2.79608 21.331 2.73591 21.035 2.71947 20.7349C2.70365 20.4353 2.72844 20.135 2.79317 19.842C2.85861 19.5451 2.96548 19.2588 3.11065 18.9917C3.2574 18.7224 3.44266 18.4761 3.66056 18.2603C3.73425 18.1904 3.81079 18.1224 3.89016 18.0562C3.99388 17.9838 4.10178 17.9176 4.2133 17.8578C4.43723 17.7218 4.664 17.5942 4.8936 17.4695L5.53705 17.1265C5.84885 16.9848 6.24569 16.7864 6.72757 16.5596C7.78736 16.1008 8.87633 15.7125 9.98734 15.3974C10.6676 15.199 11.1779 15.114 11.5747 15.0289C12.5668 14.8305 13.2755 14.7455 14.5227 14.547C16.0817 14.3203 16.4502 14.2919 17.3856 14.1218C17.9525 14.0085 18.4911 13.9234 19.143 13.7534C19.795 13.5833 20.2485 13.4415 20.5036 13.3849C21.1495 13.1956 21.7837 12.9685 22.4028 12.7046C22.6296 12.6195 23.1965 12.3928 23.8768 12.0526L24.7839 11.5991C25.8306 11.0457 26.8434 10.4305 27.8169 9.75659V9.78494C28.1639 10.7858 28.1303 11.8794 27.7225 12.857C27.3147 13.8347 26.5612 14.628 25.6059 15.0856Z"
        fill="#093545"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h142.951v28H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
