'use client';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useSession } from 'next-auth/react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { identifyUser as segmentIdentifyUser } from 'utils/segment';
import { cn } from 'utils/tailwind';

export interface HeaderProps {
  children: ReactNode;
  className?: string;
  theme?: string;
}

export const Header = ({ children, className, theme }: HeaderProps) => {
  const [stuck, setStuck] = useState(false);
  const { data: session } = useSession();

  const onboardingEnabled = useFeatureIsOn('onboarding');

  const isOnboarding =
    onboardingEnabled &&
    !session?.user?.profile?.hasActiveListing &&
    session?.user?.profile?.isOnboarding;

  const userDataStr = useMemo(
    () => JSON.stringify(session?.user),
    [session?.user]
  );

  const handleScroll = useCallback(
    (event: Event) => {
      const target = event.target as Document | null;
      const root = target?.scrollingElement as HTMLHtmlElement | null;
      const isScrolled = root == null ? false : root.scrollTop > 0;
      setStuck(isScrolled);
    },
    [setStuck]
  );

  useEffect(() => {
    if (session?.user?.profile) {
      segmentIdentifyUser(session.user.profile);
      // temporarily disabled amplitude tracking
      // amplitudeIdentifyUser(session.user);
    }
    // Session object refreshes when switching tabs. We only want to identify the user when actual
    // data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataStr]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header
      className={cn(
        'sticky top-0 z-30 transition-all duration-100 print:hidden',
        theme === 'light' ? 'bg-white' : 'bg-island-dark text-white',
        {
          'drop-shadow-sm-dark': !isOnboarding && stuck,
          'border-b border-b-disabled-light': isOnboarding,
        },
        className
      )}
    >
      <div
        className={cn('py-4', {
          'content-container': !isOnboarding,
          'onboarding-container': isOnboarding,
        })}
      >
        {children}
      </div>
    </header>
  );
};
