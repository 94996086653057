import formPlugin from '@tailwindcss/forms';
import { DarkModeConfig } from 'tailwindcss/types/config';
import tailwindAnimatePlugin from 'tailwindcss-animate';
import radixPlugin from 'tailwindcss-radix';

import { darkPlugin, focusPlugin, inputPlugin } from './utils/tailwind';

import type { Config } from 'tailwindcss';

export const BREAKPOINTS = {
  md: 600,
  lg: 1024,
  xl: 1440,
} as const;

/**
 * These are brand colors chosen by Marketing & Design.
 * When styling prefer the classes named for their usage e.g. `bg-disabled` instead of `bg-gray-dark`.
 */
export const BRAND_COLORS = {
  'garden': '#c0d20f',
  'garden-light': '#eaff26',
  'garden-dark': '#353b1b',
  'island': '#1ab2c5',
  'island-light': '#7bf1ff',
  'island-dark': '#093f45',
  'mountain': '#a848bd',
  'mountain-light': '#f8ddff',
  'mountain-dark': '#37123d',
  'desert-light': '#f9a11e',
  'desert-dark': '#c93f08',
  'woods-light': '#fff5ed',
  'woods-dark': '#550a00',
  'coast-light': '#ddf0f2',
  'coast-dark': '#41797f',
  'cool-gray': '#a0bcbf',
  'cool-gray-dark': '#5d7b7e',
  'cool-gray-light': '#e5eff0',
  'warm-gray': '#918776',
  'warm-gray-dark': '#745b50',
  'warm-gray-light': '#dad4c7',
  'gray-light': '#767676',
  'gray-dark': '#999',
  'typography': '#093545',
} as const;

const colors = {
  // Brand colors
  ...BRAND_COLORS,
  // Tints
  'island': {
    DEFAULT: BRAND_COLORS['island'],
    50: '#F6FDFE',
    100: '#E4F9FB',
    200: '#B7EFF5',
    300: BRAND_COLORS['island-light'],
    400: '#00E4FF',
    500: BRAND_COLORS['island'],
    600: '#15909E',
    700: '#0F6771',
    800: '#0A464D',
    900: BRAND_COLORS['island-dark'],
  },
  'mountain': {
    DEFAULT: BRAND_COLORS['mountain'],
    50: '#FDF5FF',
    100: '#F4E9F7',
    200: BRAND_COLORS['mountain-light'],
    300: '#D7ACE1',
    400: '#C380D1',
    500: BRAND_COLORS['mountain'],
    600: '#783188',
    700: '#54215F',
    900: BRAND_COLORS['mountain-dark'],
  },
  'desert': {
    100: '#F9E890',
    200: '#F2CD5D',
    300: BRAND_COLORS['desert-light'],
    500: BRAND_COLORS['desert-dark'],
  },
  'warm-gray': {
    DEFAULT: BRAND_COLORS['warm-gray'],
    50: '#FCFBFA',
    100: '#F8F6F4',
    200: '#DAD4C7',
    500: '#918776',
    700: '#745B50',
  },
  'cool-gray': {
    DEFAULT: BRAND_COLORS['cool-gray'],
    50: '#F5F9F9',
    100: '#E5EFF0',
    200: '#BFD2D4',
    400: '#A0BCBF',
    600: '#5D7B7E',
    900: '#1E2F31',
  },
  'woods': {
    // DEFAULT: BRAND_COLORS['woods'],
    50: '#FFF5ED',
    300: '#E9B29C',
    800: '#550A00',
  },
  'garden': {
    DEFAULT: BRAND_COLORS['garden'],
    100: '#F2FF79',
    200: '#EAFF26',
    300: '#DAEE16',
    400: '#C0D20F',
    600: '#5B6A17',
    800: '#353B1B',
  },
  'coast': {
    // DEFAULT: BRAND_COLORS['coast'],
    100: BRAND_COLORS['coast-light'],
    200: '#BCE6EB',
    600: BRAND_COLORS['coast-dark'],
  },
  'loading': {
    50: '#FCFDFD',
    100: '#FAFAFA',
    200: '#F7F8F8',
    400: '#F4F5F5',
    600: '#F2F3F3',
  },
  'error': {
    DEFAULT: '#CA2608',
    100: '#FAE9E6',
  },
  // Utility colors
  'white': '#fff',
  'black': '#000',
  'transparent': 'transparent',
  'current': 'currentColor',
  // Usage colors
  'disabled': BRAND_COLORS['gray-dark'],
  'disabled-dark': '#616161',
  'disabled-light': '#d9d9d9',
  'focus': BRAND_COLORS['island-light'],
  'success': '#1F8A5F',
  'success-dark': '#4AF3AF',
  'warning': '#F2CD5D',
  'warning-dark': '#F9E890',
  'error-light': '#fa8989',
  'cta': BRAND_COLORS['mountain'],
  'cta-light': BRAND_COLORS['mountain-light'],
} as const;

export const theme = {
  // Valid values for darkMode are 'class' & 'media'.
  // Anything else will disable the functionality. See the invert plugin for why we override this feature.
  // https://github.com/tailwindlabs/tailwindcss/blob/26cab53c15ce808160abd82f2782bc3855735492/src/corePlugins.js#L187
  darkMode: 'none' as unknown as DarkModeConfig,
  themeMode: 'owner',
  content: [
    'pages/**/*.tsx',
    'app/**/*.tsx',
    'components/**/*.tsx',
    'features/**/*.tsx',
    './.storybook/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  includeFonts: false,
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    screens: Object.fromEntries(
      Object.entries(BREAKPOINTS).map(([size, value]) => [size, `${value}px`])
    ),
    colors: {
      ...colors,
      'accent-gray': colors['cool-gray'],
    },
    fontSize: {
      'xs': ['0.875rem', { lineHeight: '1rem', fontWeight: 500 }],
      'sm': ['0.875rem', { lineHeight: '1.125rem' }],
      'base': ['1rem', { lineHeight: '1.5rem' }],
      'lg': ['1.125rem', { lineHeight: '1.625rem' }],
      'xl': ['1.75rem', { lineHeight: '2.25rem', fontWeight: 600 }],
      'heading-6': ['0.875rem', { lineHeight: '1.25rem', fontWeight: 500 }],
      'heading-6-lg': ['1rem', { lineHeight: '1.5rem', fontWeight: 600 }],
      'heading-5': ['1.125rem', { lineHeight: '1.5rem', fontWeight: 600 }],
      'heading-5-lg': ['1.25rem', { lineHeight: '1.75rem', fontWeight: 600 }],
      'heading-4': ['1.25rem', { lineHeight: '1.625rem', fontWeight: 600 }],
      'heading-4-lg': ['1.5rem', { lineHeight: '2rem', fontWeight: 600 }],
      'heading-3': ['1.375rem', { lineHeight: '1.75rem', fontWeight: 700 }],
      'heading-3-lg': ['2rem', { lineHeight: '2.75rem', fontWeight: 700 }],
      'heading-2': ['1.625rem', { lineHeight: '2rem', fontWeight: 800 }],
      'heading-2-lg': ['2.5rem', { lineHeight: '3.25rem', fontWeight: 800 }],
      'heading-1': ['2.25rem', { lineHeight: '2.625rem', fontWeight: 800 }],
      'heading-1-lg': ['3.25rem', { lineHeight: '4rem', fontWeight: 800 }],
      'table-heading': ['1rem', { lineHeight: '1.25rem', fontWeight: 600 }],
      'table-heading-lg': [
        '1.125rem',
        { lineHeight: '1.5rem', fontWeight: 600 },
      ],
    },
    data: {
      checked: 'checked~="true"',
      disabled: 'disabled~="true"',
      loading: 'loading~="true"',
      cta: 'cta~="true"',
      touched: 'touched~="true"',
    },
    extend: {
      // prefer these named spacing values, use default spacing scale otherwise
      spacing: {
        'xs': '0.25rem', // 1 on scale
        'sm': '0.5rem', // 2 on scale
        'md': '1rem', // 4 on scale
        'lg': '1.25rem', // 5 on scale
        'xl': '1.5rem', // 6 on scale
        '2xl': '2.25rem', // 9 on scale
        '3xl': '3rem', // 12 on scale
        '4xl': '3.5rem', // 14 on scale
        '5xl': '4rem', // 16 on scale
      },
      container: {
        center: true,
        padding: {
          DEFAULT: '1.5rem',
          md: '3rem',
          lg: '5rem',
        },
      },
      borderRadius: {
        blob: '45% 55% 62% 38% / 42% 46% 54% 58% ',
      },
      boxShadow: {
        'selector': '0px 0px 0px 8px #F4E9F7',
        'selector-dark': '0px 0px 0px 8px #0F6771',
        'card-elevation':
          '0px 2px 8px rgba(9, 63, 69, 0.15), 0px 4px 7px rgba(9, 63, 69, 0.06)',
        'card-elevation-dark':
          '0px 2px 8px rgba(0, 0, 0, 0.2), 0px 4px 7px rgba(0, 0, 0, 0.1)',
      },
      backgroundImage: {
        'stripes-sm':
          'linear-gradient(-45deg, #d9d9d9 7%, transparent 7%, transparent 48%, #d9d9d9 48%, #d9d9d9 57%, transparent 57%, transparent 100%)',
        'stripes-md':
          'linear-gradient(-45deg, #d9d9d9 5%, transparent 5%, transparent 50%, #d9d9d9 50%, #d9d9d9 55%, transparent 55%, transparent 100%)',
      },
      clipPath: {
        'right-third': 'polygon(67% 0, 100% 0, 100% 100%, 67% 100%)',
      },
      // Design calls this elevation
      dropShadow: {
        'sm': '0px 2px 2px rgba(9, 63, 69, 0.25)',
        'sm-dark': '0px 2px 2px rgba(0, 0, 0, 0.25)',
        'DEFAULT': [
          '0px 7px 15px rgba(9, 63, 69, 0.15)',
          '0px 25px 15px rgba(9, 63, 69, 0.06)',
        ],
        'dark': [
          '0px 7px 15px rgba(0, 0, 0, 0.15)',
          '0px 25px 15px rgba(0, 0, 0, 0.06)',
        ],
      },
      opacity: {
        15: '0.15',
        35: '0.35',
        85: '0.85',
      },
      keyframes: {
        slideDown: {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        slideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      backgroundSize: {
        'stripes-sizing-sm': '10px 10px',
        'stripes-sizing-md': '12px 12px',
      },
      listStyleImage: {
        bullet: 'url("/bullet.svg")',
        check: 'url("/bullet-check.svg")',
      },
      fontFamily: {
        sans: 'var(--font-sans)',
        heading: 'var(--font-heading)',
        tag: 'var(--font-tag)',
      },
      gridTemplateColumns: {
        270: 'repeat(auto-fill, minmax(260px, 1fr))',
      },
      animation: {
        slideDown: 'slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1)',
        slideUp: 'slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1)',
        loading: 'loading 1.75s linear infinite',
      },
    },
  },
  plugins: [
    tailwindAnimatePlugin,
    formPlugin({ strategy: 'class' }),
    radixPlugin,
    inputPlugin,
    darkPlugin,
    focusPlugin,
  ],
} satisfies Partial<Config>;

if (Array.isArray(theme.content)) {
  theme.content = theme.content.map((path) => {
    return typeof path === 'string' ? path.replace('/C:', 'C:') : path;
  });
}

export const themeColors = theme.theme.colors;
export default theme;
