'use client';

import { useId } from 'react';

import { cn } from 'utils/tailwind';

export const LogoSmall = ({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  const clipId = useId();

  return (
    <svg
      {...props}
      className={cn('inline-block h-6 lg:h-7', className)}
      fill="none"
      height="24"
      viewBox="0 0 23 24"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M16.3389 9.65511L12.6013 5.97623C12.3536 5.74119 12.0229 5.60986 11.6788 5.60986C11.3347 5.60986 11.004 5.74119 10.7562 5.97623L7.02107 9.65511C6.77783 9.8966 6.64173 10.2231 6.64258 10.5632V13.823C7.48185 13.4711 8.35311 13.1982 9.24466 13.008V11.0987L11.6812 8.70047L14.1176 11.0987V12.3095C15.0165 12.1698 15.4423 12.0999 15.8681 12.0068C16.081 11.9602 16.3885 11.8904 16.7434 11.7972V10.5865C16.729 10.2377 16.585 9.90633 16.3389 9.65511Z"
          fill="#C0D20F"
        />
        <path
          d="M22.1343 6.8844C20.9475 4.50532 18.9611 2.60365 16.512 1.50206C14.0629 0.400478 11.3021 0.166827 8.69797 0.840755C6.09382 1.51468 3.80674 3.05468 2.22481 5.19945C0.642878 7.34421 -0.13648 9.96165 0.0189775 12.6076C0.0292276 12.7898 0.0759842 12.9681 0.156555 13.1324C0.237125 13.2967 0.349918 13.4437 0.488437 13.5649C0.626956 13.686 0.788464 13.7791 0.963662 13.8386C1.13886 13.8981 1.32428 13.9229 1.50926 13.9115C1.72729 13.8987 1.93939 13.8366 2.12903 13.7299C2.35944 13.5997 2.54811 13.4085 2.67357 13.178C2.79902 12.9475 2.85623 12.6869 2.83868 12.426V12.0069C2.83787 10.0231 3.5256 8.09853 4.78794 6.55204C6.05028 5.00555 7.81148 3.92994 9.77977 3.50339C11.7481 3.07684 13.8053 3.32495 15.6108 4.20661C17.4163 5.08827 18.8615 6.55058 19.7072 8.3513C19.9563 8.88875 20.1466 9.45072 20.275 10.0278C21.1454 9.56797 21.9903 9.06276 22.8061 8.51429C22.6378 7.95034 22.4127 7.40434 22.1343 6.8844Z"
          fill="#1AB2C5"
        />
        <path
          d="M22.2763 16.8499C20.9667 19.6123 18.5984 21.7513 15.69 22.7987C12.7816 23.8461 9.57001 23.7164 6.7585 22.4381C5.57551 21.8937 4.49113 21.1623 3.54849 20.2727C3.12294 19.8583 2.7277 19.4148 2.36572 18.9455C2.60228 18.7825 2.72055 18.7127 2.74421 18.6894C3.47752 18.2004 4.16352 17.7813 4.80221 17.432C5.1931 17.9061 5.62872 18.3427 6.10325 18.7359C7.91664 20.1914 10.243 20.8786 12.5709 20.6463C14.8988 20.414 17.0378 19.2813 18.5175 17.4972C18.9829 16.9443 19.3726 16.3337 19.6766 15.6811C19.8091 15.3833 20.0557 15.149 20.3626 15.0291L20.3863 15.0058C20.5571 14.9324 20.741 14.8929 20.9274 14.8894C21.1138 14.886 21.299 14.9187 21.4726 14.9857C21.6461 15.0527 21.8045 15.1528 21.9388 15.2801C22.0731 15.4074 22.1805 15.5595 22.2551 15.7276C22.3356 15.9035 22.379 16.0937 22.3827 16.2866C22.3864 16.4796 22.3502 16.6712 22.2763 16.8499Z"
          fill="#C0D20F"
        />
        <path
          d="M20.9513 12.8916L20.8803 12.9381C20.762 13.008 20.3835 13.171 19.8395 13.4038C19.4379 13.5799 19.0273 13.7353 18.6094 13.8695C17.9944 14.1023 17.4976 14.242 17.261 14.3119C16.6697 14.4982 16.1729 14.5913 15.8654 14.6612C15.4396 14.7543 15.0374 14.8241 14.1149 14.9638C13.1923 15.1036 12.3407 15.22 12.0096 15.2665C11.087 15.383 10.1881 15.5227 9.24191 15.7322C8.35274 15.9412 7.48247 16.2215 6.63983 16.5704C6.00687 16.8277 5.39066 17.1232 4.79472 17.4552C4.15603 17.8045 3.47003 18.2236 2.73672 18.7126L2.35823 18.9454V18.9221C2.35823 18.9058 2.31565 18.8616 2.30382 18.8453C2.292 18.829 2.26834 18.7917 2.25178 18.7661C2.23522 18.7405 2.18318 18.6567 2.15243 18.6008C2.09375 18.4888 2.04316 18.3729 2.00104 18.2539C1.91593 18.0217 1.86573 17.7785 1.85201 17.5321C1.8388 17.286 1.85949 17.0393 1.91351 16.7986C1.96812 16.5547 2.0573 16.3196 2.17845 16.1001C2.30092 15.879 2.45552 15.6766 2.63736 15.4994C2.69887 15.4419 2.76274 15.3861 2.82897 15.3317C2.91553 15.2722 3.00558 15.2178 3.09864 15.1687C3.28552 15.057 3.47476 14.9522 3.66637 14.8498L4.20334 14.568C4.46355 14.4516 4.79472 14.2886 5.19686 14.1023C6.08128 13.7255 6.99005 13.4065 7.91722 13.1477C8.48494 12.9847 8.91074 12.9148 9.24191 12.845C10.0698 12.682 10.6612 12.6122 11.7021 12.4492C13.0031 12.2629 13.3106 12.2396 14.0912 12.0999C14.5643 12.0068 15.0138 11.9369 15.5579 11.7972C16.1019 11.6575 16.4804 11.5411 16.6933 11.4945C17.2323 11.3391 17.7615 11.1525 18.2782 10.9357C18.4675 10.8659 18.9406 10.6796 19.5083 10.4002L20.2653 10.0276C21.1388 9.5731 21.984 9.06771 22.7964 8.51416V8.53744C23.086 9.35957 23.0579 10.2579 22.7176 11.0609C22.3773 11.864 21.7485 12.5157 20.9513 12.8916Z"
          fill="#C0D20F"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect
            fill="white"
            height="23"
            transform="translate(0 0.5)"
            width="23"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
