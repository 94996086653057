import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { type PropsWithChildren } from 'react';

import { cn } from 'utils/tailwind';

import { Header } from '../Header';
import { HeaderLogo } from '../Header/HeaderLogo';

export default function NoNavLayout({ children }: PropsWithChildren) {
  const maintenanceMode = useFeatureIsOn('maintenance-mode');
  return (
    <>
      {!maintenanceMode && (
        <Header>
          <div className="flex h-full items-center justify-center">
            <HeaderLogo />
          </div>
        </Header>
      )}
      <div
        className={cn('relative z-0 flex flex-1 flex-col bg-cover', {
          "md:bg-[url('/speckle-bg.png')] bg-cool-gray-50": !maintenanceMode,
          'bg-warm-gray-100': maintenanceMode,
        })}
      >
        {children}
      </div>
    </>
  );
}
