'use client';
import { useBreakpoint } from 'use-breakpoint';

import { BREAKPOINTS } from 'tailwind.config';

type Breakpoint = keyof typeof BREAKPOINTS;

export interface Breakpoints {
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
}

export function useBreakpoints(): Breakpoints {
  const { minWidth } = useBreakpoint(BREAKPOINTS, 'sm' as Breakpoint);
  return {
    isSm: true,
    isMd: minWidth >= BREAKPOINTS.md,
    isLg: minWidth >= BREAKPOINTS.lg,
    isXl: minWidth >= BREAKPOINTS.xl,
  };
}
