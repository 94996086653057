'use client';
import NoNavLayout from 'components/layout/NoNavLayout';

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return <NoNavLayout>{children}</NoNavLayout>;
}
